//###########################################################################
// Newsletter
//###########################################################################

const newsletter = {
    config: {
        newsletterForm: '.newsletter__form form, .newsletter-bottom__form form'
    },

    elements: {
        newsletterForm: null
    },

    init() {
        const self = this

        self.elements.newsletterForms = document.querySelectorAll(self.config.newsletterForm)

        // For each newsletter form, handle submission
        //
        if (self.elements.newsletterForms !== null) {
            for (let i = 0; i < self.elements.newsletterForms.length; i++) {
                const form = self.elements.newsletterForms[i]

                self.handleNewsletter(form)
            }
        }
    },

    handleNewsletter(form) {
        const self = this

        form.addEventListener('submit', function (e) {
            e.preventDefault()

            const gdprCheckbox = form.querySelector('#gdpr-consent')
            const optInFeedback = form.querySelector('.opt-in-mailchimp-feedback')

            if (gdprCheckbox.checked) {
                optInFeedback.innerHTML = ''

                if (window.ga) {
                    window.ga('send', 'event', 'Form', 'Submission', 'Newsletter');
                }

                if (window.gtag) {
                    gtag('event', 'Submission', {
                        'event_category': 'Form',
                        'event_label': 'Newsletter',
                        'value': 0
                    });
                }

                form.submit()
            } else {
                optInFeedback.innerHTML = '<div class="opt-in-error"><p>Please use the checkbox below to opt-in</p></div>'
            }
        })
    }
}

window.addEventListener('load', function () {
    newsletter.init()
})